
import { defineComponent, onMounted, defineProps, watch, ref } from 'vue'
import * as echarts from 'echarts'
export default defineComponent({
  props: {
    barData: {
      type: Array,
      default: () => []
    }
  },
  setup (props) {
    onMounted(() => {
      dateList.value = []
      tranList.value = []
      doingList.value = []
      noTranList.value = []
      props.barData.forEach((res:any) => {
        dateList.value.push(res.createdTime)
        tranList.value.push(res.tradedNumTotal)
        doingList.value.push(res.underwayNumTotal)
        noTranList.value.push(res.unsettledNumTotal)
      })
      dateList.value = dateList.value.slice(-5)
      tranList.value = tranList.value.slice(-5)
      doingList.value = doingList.value.slice(-5)
      noTranList.value = noTranList.value.slice(-5)
      // tranList.value = tranList.value.slice((tranList.value.length - 5), tranList.value.length)
      // doingList.value = doingList.value.slice((doingList.value.length - 5), doingList.value.length)
      // noTranList.value = noTranList.value.slice((noTranList.value.length - 5), noTranList.value.length)
      // dateList.value = dateList.value.slice((dateList.value.length - 5), dateList.value.length)
      getBarData()
    })
    const dateList = ref<any>()
    const tranList = ref<any>()
    const doingList = ref<any>()
    const noTranList = ref<any>()
    watch(
      () => props.barData,
      (res:any) => {
        dateList.value = []
        tranList.value = []
        doingList.value = []
        noTranList.value = []
        res.forEach((res:any) => {
          dateList.value.push(res.createdTime)
          tranList.value.push(res.tradedNumTotal)
          doingList.value.push(res.underwayNumTotal)
          noTranList.value.push(res.unsettledNumTotal)
        })
        dateList.value = dateList.value.slice(-5)
        tranList.value = tranList.value.slice(-5)
        doingList.value = doingList.value.slice(-5)
        noTranList.value = noTranList.value.slice(-5)
        // dateList.value = dateList.value.slice((dateList.value.length - 5), dateList.value.length)
        // tranList.value = tranList.value.slice((tranList.value.length - 5), tranList.value.length)
        // doingList.value = doingList.value.slice((doingList.value.length - 5), doingList.value.length)
        // noTranList.value = noTranList.value.slice((noTranList.value.length - 5), noTranList.value.length)
        getBarData()
      }
    )
    const getBarData = () => {
      var app: any = {}
      type EChartsOption = echarts.EChartsOption;

      var chartDom = document.getElementById('main')!
      var myChart = echarts.init(chartDom)
      var option: EChartsOption

      const posList = [
        'left',
        'right',
        'top',
        'bottom',
        'inside',
        'insideTop',
        'insideLeft',
        'insideRight',
        'insideBottom',
        'insideTopLeft',
        'insideTopRight',
        'insideBottomLeft',
        'insideBottomRight'
      ] as const

      app.configParameters = {
        rotate: {
          min: -90,
          max: 90
        },
        align: {
          options: {
            left: 'left',
            center: 'center',
            right: 'right'
          }
        },
        verticalAlign: {
          options: {
            top: 'top',
            middle: 'middle',
            bottom: 'bottom'
          }
        },
        position: {
          options: posList.reduce(function (map, pos) {
            map[pos] = pos
            return map
          }, {} as Record<string, string>)
        },
        distance: {
          min: 0,
          max: 100
        }
      }
      type BarLabelOption = NonNullable<echarts.BarSeriesOption['label']>;
      app.config = {
        rotate: 90,
        align: 'left',
        verticalAlign: 'middle',
        position: 'insideBottom',
        distance: 15,
        onChange: function () {
          const labelOption: BarLabelOption = {
            rotate: app.config.rotate as BarLabelOption['rotate'],
            align: app.config.align as BarLabelOption['align'],
            verticalAlign: app.config
              .verticalAlign as BarLabelOption['verticalAlign'],
            position: app.config.position as BarLabelOption['position'],
            distance: app.config.distance as BarLabelOption['distance']
          }
          myChart.setOption<echarts.EChartsOption>({
            series: [
              {
                label: labelOption
              },
              {
                label: labelOption
              },
              {
                label: labelOption
              },
              {
                label: labelOption
              }
            ]
          })
        }
      }
      const labelOption: BarLabelOption = {
        show: true,
        position: app.config.position as BarLabelOption['position'],
        distance: app.config.distance as BarLabelOption['distance'],
        align: app.config.align as BarLabelOption['align'],
        verticalAlign: app.config
          .verticalAlign as BarLabelOption['verticalAlign'],
        rotate: app.config.rotate as BarLabelOption['rotate'],
        formatter: '{c}  {name|{a}}',
        fontSize: 16,
        rich: {
          name: {}
        }
      }

      option = {
        color: ['#91CC75', '#FAC858', '#EE6666'],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {
          data: ['已成交', '进行中', '未成交']
        },
        toolbox: {
          show: true,
          orient: 'vertical',
          left: 'right',
          top: 'center',
          feature: {
            mark: { show: true },
            dataView: { show: false, readOnly: false },
            magicType: { show: true, type: ['line', 'bar', 'stack'] },
            restore: { show: true },
            saveAsImage: { show: true }
          }
        },
        xAxis: [
          {
            type: 'category',
            axisTick: { show: false },
            data: dateList.value
          }
        ],
        yAxis: [
          {
            type: 'value'
          }
        ],
        series: [
          {
            name: '已成交',
            type: 'bar',
            emphasis: {
              focus: 'series'
            },
            data: tranList.value

          },
          {
            name: '进行中',
            type: 'bar',
            emphasis: {
              focus: 'series'
            },
            data: doingList.value

          },
          {
            name: '未成交',
            type: 'bar',
            emphasis: {
              focus: 'series'
            },
            data: noTranList.value
          }

        ]
      }
      option && myChart.setOption(option, true)
    }
    return {
      dateList,
      tranList,
      doingList,
      noTranList,
      getBarData
      // app,
      // chartDom,
      // myChart,
      // option
    }
  }
})
